/**
 * Labstep
 *
 * @module grid/Report/components/Filter/ExperimentAuthor
 * @desc Experiment Author filter
 */

import UserSearchSelect from 'labstep-web/components/User/SearchSelect';
import { ParamsStateContextProvider } from 'labstep-web/contexts/params-state';
import ActionComponent from 'labstep-web/core/Action/Component';
import Modal from 'labstep-web/core/Modal/Default';
import { User } from 'labstep-web/models/user.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { PostFilterExperimentAuthor } from 'labstep-web/services/postFilter/filters/author';
import React from 'react';
import { GridReportFilterExperimentAuthorProps } from './types';

const GridReportFilterExperimentAuthor: React.FC<
  GridReportFilterExperimentAuthorProps
> = ({ addPostFilter, ...rest }) => (
  <ParamsStateContextProvider value={{}}>
    <Modal
      header={`Filter by Created by`}
      viewComponent={({ toggleModal }): React.ReactNode => (
        <ActionComponent
          onClick={toggleModal}
          type="option"
          icon="user circle"
          text="Filter by this field"
        />
      )}
      content={({ toggleModal }): React.ReactElement => (
        <UserSearchSelect
          onChange={(selectedOption): void => {
            if (addPostFilter) {
              addPostFilter(
                PostFilterExperimentAuthor.getNode([
                  selectedOption.guid,
                ]),
                true,
              );
            }
            toggleModal();
          }}
          placeholder={`Select ${getHumanReadableEntityName(
            User.entityName,
            true,
          )} from the list below`}
          minimumCharacters={3}
        />
      )}
      {...rest}
    />
  </ParamsStateContextProvider>
);

export default GridReportFilterExperimentAuthor;
